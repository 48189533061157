import React from "react";
import { isBrowser } from "@emotion/core/src/utils";
import Insight from "../templates/Insight";
import { graphql, useStaticQuery } from "gatsby";

const unpublishedInsight = ({data}) => {
    if(!isBrowser) {
        return null;
    }
    const previewData = window.__PRISMIC_PREVIEW_DATA__

    return <Insight data={previewData} previewImage={data.file} />
}

export default unpublishedInsight;

export const pageQuery = graphql`
    query UnpublishedInsightQuery {
        file(name: {eq: "placeholder-insights-image"}) {
            childImageSharp {
                fluid(maxWidth: 5000) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`;